.secondary-background {
    background-color: $secondary-color;
}

.dark-blue-background {
    background-color: $dark-blue;
}

.text-light-blue {
    color: $light-blue;
}

.text-turquoise {
    color: $turquoise;
}