.slider-nav {

    &__item {
        @extend %primary-font;
        @include sliderLinks($black);
        font-size: $h5-font-size;
        
        img {
            padding: rem(10px);
        }

        .component__link {
            a {
                border: none;
                border-radius: 5px;
                margin-top: -20px;
                padding: 20px 25px 15px;
                width: 100%;
            }
          }

          &.is-active,
          :hover {
            .component__link {

                a {
                    border: none;
                    background-color: $brown;
                    cursor: pointer;
                }
                
            }
          }
    }
}