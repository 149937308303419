.header {
  background-color: $header-background-color;
  background: $header-background-image;
  background-size: cover;
  &__logo {
    padding-top: 0 !important;
  }

  .main-menu {
    li {
      @include breakpoint(md) {
        margin-right: 2.5rem;
      }
    }
  }
}
