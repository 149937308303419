$blue: #215a8d;
$light-blue:#89c0f1;
$turquoise: #c2eaf1;
$dark-blue: #023560;
$lynch: #60808b;
$pink: #c65855;
$light-pink: #da8573;
$cream: #dfd8af;
$yellow: #fdf6bd;
$supernova: #FFBA26;
$red: #8d243d;
$black: #000;
$green: #88b984;
$light-green: #8cc1a4;
$white: white;
$max-width: 1060px;
$grey: #5f5f5f;
$orange: #cb4e34;
$purple: #8174b5;
$another-blue: #89c0f1;
$brown: #c9ae5f;

$animation-time: 0.3s;
$primary-color: $yellow;
$secondary-color: $blue;
$supapress-color: $cream;
$section-border-top: $dark-blue;
$background-body: $secondary-color;
$body-font-color: $black;
$background-buy-section: rgba(64, 107, 127, 0.29);

$assetBackgrounds: $pink, $blue, $orange, $green, $purple, $orange;

/* Header */

$header-background-color: $primary-color;
$header-background-image: url("https://supadu-io.imgix.net/harpercollins-septimusheap/septimusheap-site/header-background.jpg?q=50&w=0.7&auto=format")
  no-repeat center center;

$link-color: $turquoise;
$link-color-hover: $primary-color;


/* NAVIGATION */
$nav-links-colour: $primary-color;
$nav-links-colour-hover: $supernova;
$nav-current-link-colour: $supernova;
$nav-background-colour: $red;

/* default font sizes */

$base-font-size: 16px;
$h1-font-size: 50px;
$h2-font-size: 40px;
$h3-font-size: 30px;
$h4-font-size: 20px;
$h5-font-size: 16px;
