.book-landing {
    .book-title {
        a {
            color: $black;

            &:hover {
                color: $blue;
            }
        }
    }
}