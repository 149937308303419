.supafolio-loader-container {
    .supafolio-loader {
        border-top-color: $secondary-color;
    }
}
.book-detail {
    color: $supapress-color;
    background-color: $blue;
    .book-content {
        &__top {
            padding-top: 24px;
            background: $dark-blue;
            @include full_width_colour($dark-blue);
            @media (max-width: 767px) {
                img {
                    max-width: 75%;
                    margin: 0 auto;
                }
            }
            @media (min-width: 768px) {
                padding-top: 64px;
            }
        }
    }
    h1 {
        @media (max-width: 767px) {
            font-size: 38px;
            line-height: 1.3;
        }
        line-height: 1.4em;
    }

    h2 {
        @extend %primary-font;
        color: $supapress-color;
        font-size: rem(35px);
        line-height: 1.4em;
    }

    h3,
    h3 + p {
        @extend %main-font;
        font-size: 20px;
    }
    .flextabs-container {
        background: $dark-blue;
        @include full_width_colour($dark-blue);
    }
    .product-tabs {
        width: 100%;
        padding-top: 10px;
        background-color: $dark-blue;
        color: $white;
        @include breakpoint(md) {
            padding-top: 0;
        }
        &:before,
        &:after {
            z-index: 1;
            background-color: $dark-blue;
        }

        :before,
        :after {
            background-color: $secondary-color;
        }

        .flextabs {
            &__tab {
                background-color: $dark-blue;
                @extend %primary-font;
                font-size: rem(16px);
                button {
                    padding: rem(15px);
                    text-transform: uppercase;
                    color: $white;
                }

                .flextabs__toggle--active {
                    background-color: $secondary-color;
                    border-color: $white $white $white;
                    border-bottom-color: $secondary-color;

                    @include breakpoint(md) {
                        background-color: transparent;
                    }
                }

                .flextabs__toggle--active--last {
                    @include breakpoint(md) {
                        background-color: $secondary-color;
                        border-color: $white $white $white;
                        border-bottom-color: $secondary-color;
                    }
                }
            }

            &__content {
                background-color: $secondary-color;
                padding: rem(20px) rem(15px);
                @include breakpoint(md) {
                    padding: rem(40px) rem(15px);
                }
            }
        }

        .flextabs__content:after,
            .flextabs__content:before,
            .flextabs__content--active--last {
                border-top-color: $white;
                background-color: $blue
            }
    }
    
    .format {
        @extend %primary-font;
        margin-bottom: rem(20px);
    }
    .price {
        font-size: rem(24px);
        font-weight: bold;
    }

    .retailers-wrapper {
        color: $black;

        .other-retailers {
            padding: rem(10px);
        }

        .retailers {
            a {
                color: $black;
                &:hover {
                    color: $red;
                }
            }
        }

    }

    .buy-button-wrapper {
        background: $background-buy-section;
    }

    .buy-button {
        text-transform: uppercase;
        padding: 16px 20px;
    }
    
    .button-asset {
        @extend %main-font;
        padding: 0;
        a {
            display: block;
            padding: 15px 30px;
        }
        &:hover {
            a {
                color: $red;
            }
        }
        &--audio {
            padding: 10px 30px;
        }
    }

    .other-formats {
        > p:first-child {
            @extend %primary-font;
        }
        ul {
            li {
                a {
                    color: $white;
                    &:hover {
                        color: $primary-color;
                    }
                }
            }
        }
    }
    .related-books {
        &-title {
            @media (min-width: 768px) {
                padding-top: 30px;
                border-top: 3px solid $dark-blue;
            }
            h1 {
                font-size: 35px;
            }
        }
    }
}