.character {
    &__image {
        max-width: 200px;;
    }

    .component {
        &__text {
            ul li {
                margin-bottom: rem(10px);
            }
        }
    }
}