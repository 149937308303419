.coming-soon {
    &__card {
        background: $header-background-image;
        background-size: cover;
        padding: 50px 0;
        h1 {
            text-shadow: 0px 1px 2px rgba(black, 0.9), 1px 2px 4px rgba(black, 0.8);
        }
        img {
            margin: 0 auto;
        }
    }
}