@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.primary-background {
    background-color: $primary-color;
    @include full_width_colour($primary-color);
}

.dark-blue-background {
    background-color: $dark-blue;
    @include full_width_colour($dark-blue);
}