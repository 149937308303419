.primary-button {
    @extend %primary-font;
    @include siteButton($red, $white);
}

.format-button.primary-button {
    border: 2px solid $primary-color;
    color: $primary-color;
    &.active {
        color: $white;
    }
    &:hover,
    &.active {
        background-color: $primary-color;
        &:after {
            border-top-color: $primary-color;
        }
    }
}

.secondary-button {
    @extend %secondary-font;
    @include siteButton($secondary-color, $white);
    font-size: 20px;
    &__character {
        display: inline-block;
        text-align: center;
    }
}

.red-button {
    @extend %primary-font;
    @include siteButton($red, $white);
    text-transform: uppercase;
}

.white-button {
    @extend %secondary-font;
    @include siteButton($white, $secondary-color);
}

.button-asset--audio {
    @include siteButton($white, $black);
    &:hover {
        background-color: $white;
        color: $black;
    }
}

.back-button {
    margin: 20px 0;
    a {
        &:hover {
            color: $black;
        }
    }
}